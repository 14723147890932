<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h3 class="display-2"> Sasaran Kinerja Tambahan Pegawai</h3>
          </template>
          <crud
            :crudSettings="crudSettings"
            :headers="tahun >= 2022 ? headers_2022 : headers"
            :responseData="items"
            :detailData="detailData"
            :forceUpdateTable="forceUpdate"
            @onTableChange="updateTable"
            @onEditClick="edit"
            @onDeleteClick="remove"
          >
          <template v-slot:append-header>
            <v-spacer></v-spacer>
            <v-row>
              <v-btn color="primary" small disabled class="mr-3"><v-icon small left>mdi-target</v-icon> Sasaran Kinerja Utama</v-btn>
              <v-btn  color="primary" small disabled class="mr-3"><v-icon small left>mdi-file-excel</v-icon> Export Excel</v-btn>
              <v-btn @click="createItem()" color="primary" small class="mr-3"><v-icon small left>mdi-plus</v-icon> Tambah SKP Tambahan</v-btn>
            </v-row>
          </template>
          </crud>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogTambahSKPTambahan" max-width="600px" :eager="true">
      <v-card>
        <v-card-text>
          <v-form ref="formTambahSKPTambahan">
            <h1 class="display-2 my-2">Entri Rencana Kinerja Tambahan</h1>

            <v-text-field dense v-model="formEdit.rencana_kinerja" :rules="[rules.required]" outlined label="Rencana Kinerja Tambahan"></v-text-field>
            <v-text-field dense v-model="formEdit.indikator_tambahan" :rules="[rules.required]" outlined label="Indikator Kinerja Tambahan"></v-text-field>
            <v-select dense v-model="formEdit.aspek" :items="listAspek2" item-text="text" item-value="value" label="Aspek" outlined ></v-select>
            <v-row>
              <v-col>
                <v-text-field dense v-model="formEdit.target_bawah" :rules="[rules.required, rules.onlyInt]" outlined label="Target Batas Bawah"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field dense v-model="formEdit.target_atas" outlined label="Target Batas Atas"></v-text-field>
              </v-col>
              <v-col>
                <v-select dense v-model="formEdit.satuan_target" :rules="[rules.required]" outlined :items="satuanSKP" label="Satuan"></v-select>
              </v-col>
            </v-row>
            <!-- Penambahan perspektif Permenpan 6 untuk JPT -->
            <v-select v-if="tahun >= 2022" dense v-model="formEdit.perspektif" :items="listPerspektif" :rules="[rules.required]" item-text="text" item-value="value" label="Perspektif" outlined ></v-select>
            <v-select v-else dense v-model="formEdit.lingkup_penugasan" :items="lingkupPenugasan" item-text="text" item-value="value" label="Lingkup Penugasan" outlined ></v-select>
            <v-text-field dense v-model="formEdit.nama_kegiatan" :rules="[rules.required]" outlined label="Nama Kegiatan"></v-text-field>
            <v-text-field dense v-model="formEdit.no_sk" :rules="[rules.required]" outlined label="No. SK"></v-text-field>
            <v-text-field dense v-model="formEdit.keterangan" :rules="[rules.required]" outlined label="Keterangan"></v-text-field>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogTambahSKPTambahan=false">Tutup</v-btn>
          <v-btn color="success" @click="simpanItem()">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import store from '@/store' 
import Crud from '@/components/common/Crud'
import SKPService from '@/services/SKPService'
import {defaultRules} from '@/utils/lib'
export default {
  components:{
    Crud,
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {text:'No', value:'nomer', sortable:false, class:'header-index-profile'},
        {text:'No. SK', value:'no_sk', sortable:false, class:'header-index-profile'},
        {text:'Lingkup Penugasan', value:'lingkup_penugasan', sortable:false, class:'header-index-profile'},
        {text:'Rencana Kinerja Tambahan', value:'rencana_kinerja', sortable:false, class:'header-index-profile'},
        {text:'Nama Kegiatan', value:'nama_kegiatan', sortable:false, class:'header-index-profile'},
        {text:'Aspek', value:'aspek', sortable:false, class:'header-index-profile'},
        {text:'Target', value:'target_bawah', sortable:false, class:'header-index-profile'},
        {text:'Keterangan', value:'keterangan', sortable:false, class:'header-index-profile'},
        {value:'aksi_skp_tambahan', sortable:false, class:'header-index-profile'},
      ],
      headers_2022:[
        {text:'No', value:'nomer', sortable:false, class:'header-index-profile'},
        {text:'No. SK', value:'no_sk', sortable:false, class:'header-index-profile'},
        {text:'Perspektif', value:'perspektif', sortable:false, class:'header-index-profile'},
        {text:'Rencana Kinerja Tambahan', value:'rencana_kinerja', sortable:false, class:'header-index-profile'},
        {text:'Nama Kegiatan', value:'nama_kegiatan', sortable:false, class:'header-index-profile'},
        {text:'Aspek', value:'aspek', sortable:false, class:'header-index-profile'},
        {text:'Target', value:'target_bawah', sortable:false, class:'header-index-profile'},
        {text:'Keterangan', value:'keterangan', sortable:false, class:'header-index-profile'},
        {value:'aksi_skp_tambahan', sortable:false, class:'header-index-profile'},
      ],
      items:{},

      tableValues:{},
      dialogReset:false,
      // crudSettings:{
      //   title:'',
      //   detailTitle:'Detail',
      //   enableHeader:true,
      //   enableDetail:false,
      //   enableCreate:false,
      //   enableEdit:false,
      //   enableDelete:false,
      // },
      rules:{
        ...defaultRules
      },
      formEdit:{},
      listAspek2: [
        "Kuantitas",
        "Kualitas",
        "Waktu"
      ],
      lingkupPenugasan: [
        {text: "Dalam Satu Perangkat Daerah", value: 1},
        {text: "Antar Perangkat Daerah", value: 2},
        {text: "Antar Daerah", value: 3},
      ],
      satuanSKP: [],
      dialogTambahSKPTambahan: false,
      tahun: null,
      listPerspektif: [
        {value:"Perspektif penerima layanan", text: "Perspektif penerima layanan"},
        {value:"Perspektif proses bisnis", text: "Perspektif proses bisnis"},
        {value:"Perspektif penguatan internal", text: "Perspektif penguatan internal"},
        {value:"Perspektif anggaran", text: "Perspektif anggaran"},
      ],
    }
  },

  watch:{},

  created(){
    this.tahun = this.$store.getters["user/data"].tahun
    this.tableValues = {itemsPerPage:10, page: 1}
    this.updateTable(this.tableValues)
    // mengambil database
    SKPService.satuanSKP().then(response=>{
      this.satuanSKP = response.data.data.sort((a,b)=>{
        return a.toLowerCase().localeCompare(b.toLowerCase())
      })
    })
  },

  computed:{
    crudSettings(){
      return{
        title:'',
        detailTitle:'Detail',
        enableHeader: true,
        deleteTitleKey:'rencana_kinerja',
        enableEdit:this.items.is_nip_listed_edit_tahunan || this.items.is_nip_listed_lapor_triwulan || this.items.periode_tombol_lapor_skp_triwulan || this.enableAll || this.items.periode_input_skp || this.items.buka_tambah_skp_triwulan_by_nip || this.items.periode_input_jabatan_baru,
        enableDelete:this.items.is_nip_listed_hapus_tahunan || this.items.is_nip_listed_lapor_triwulan || this.items.periode_tombol_lapor_skp_triwulan || this.enableAll || this.items.periode_input_skp || this.items.buka_tambah_skp_triwulan_by_nip || this.items.periode_input_jabatan_baru,
        enableDetail:false,
        enableCreate:false,
      }
    },
  },

  methods:{
    clearForm(){
      this.formEdit = {}
    },
    createItem(){
      this.clearForm()
      this.dialogTambahSKPTambahan=true
      this.$refs.formTambahSKPTambahan.resetValidation()
      setTimeout(()=>{
        document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
      },500)
    },
    simpanItem(){
      if (this.$refs.formTambahSKPTambahan.validate()){
        SKPService.storeSKPTambahan(this.formEdit, this.formEdit.id).then(response => {
          let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message: res.message, color:'success'})
            this.dialogTambahSKPTambahan=false
            this.updateTable()
          } else {
            store.commit('snackbar/setSnack',{message: res.message, color:'error'})
          }
        })
      }
    },
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){
      this.tableValues = val? val : this.tableValues

      if(this.tableValues){
        SKPService.getSKPTambahan({
          search:this.tableValues.search ? this.tableValues.search: '',
          page: this.tableValues.page
        }).then( response => {
          console.log(response)
          this.items = response.data
          this.loading = false
        })
      }
    },

    edit(item){
      console.log(item)
      this.dialogTambahSKPTambahan = true
      this.formEdit = _.clone(item)
    },

    remove(item){
      SKPService.hapusSKPTambahan(item.id).then(response=>{
        console.log(response.data)
        let res = response.data
        if(res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.updateTable()
        }else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      })
    },
  },
}
</script>

<style lang="css" scoped>
</style>
