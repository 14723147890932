import request from "@/utils/request";
import store from "@/store";
import _g from "../../src/global";

const SKPService = {
  getSKP,
  getDataPengaturanSKP,
  getSKPYangSudahDilaporkan,
  getSKPTambahan,

  // skp tahunan
  getDataValidasiSKPTahunan,
  getDropdownListSKPTahunan,
  syncSakipTahunan,
  storeSKPTahunan,
  laporSKPTahunan,
  exportSKPTahunan,
  hapusSKPTahunan,
  getPengaturanSKP,
  editPengaturanSKP,
  storeAssignmentsSKP,
  storeLampiranAngkaKredit,
  insertPenilaianSKP,
  insertPenilaianSKPPermenpan62022,
  insertPenilaianSKPPermenpan6,

  // triwulan
  getSKPTriwulan,
  getDataValidasiSKPTriwulan,
  hapusSKPTriwulan,
  storeTriwulan,
  laporTriwulan,
  syncSakipTriwulan,

  // skp tambahan
  storeSKPTambahan,
  hapusSKPTambahan,

  // buka input SKP
  bukaInputSKPGetDataPegawai,
  bukaInputSKPGetDataPLT,

  // validasiSKP
  tolakValidasiSKP,
  terimaValidasiSKP,
  getAktivitasTerkait,
  submitValidasiSKPTahunan,
  storeKeteranganBobotSKPTriwulan,
  getBawahanValidasiSKP,
  getBawahanValidasiSKPPerNIP,

  // lain-lain
  satuanSKP,
  getPegawaiByNip,
  getTupoksi,
  getKegiatan,
  getSasaranStrategis,
  getSKPAtasan,
  skpGetDetail,
  feedbackEvaluasi,

  // tidak dipakai
  uploadSKPEvidence,
  getListPeriodeSKP,

  hapusPengaturanSKP,
  storeLampiranSKP,
  getLampiranSKP,
  getEkspektasiPerilakuKerja,
  storeEkspektasiPerilakuKerja,
  getPenilaianSKPPermenpan6,
  getDataVerifikasiSKP,
  storeVerifikasiSKP,
  getPegawaiSKPD,
  getDataDistribusiPenilaianKinerja,
  getAtasan,
  getAtasanPenilai,

  //Admin
  getListTutupSKP,
};

function getSKP({ page, perpage, search, order, order_direction, triwulan }) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-skp-tahunan",
    method: "post",
    data: {
      nip: current.username,
      page: page,
      perpage: perpage ? perpage : 20,
      triwulan: triwulan ? triwulan : "tahun",
      search: search ? search : "",
      order: order ? order : "sasaran",
      order_direction: order_direction ? order_direction : "asc",
    },
  });
}

function getSKPTriwulan({ page, perpage, search, triwulan }) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-skp-triwulan",
    method: "post",
    data: {
      nip: current.username,
      page: page,
      perpage: perpage ? perpage : 20,
      triwulan: triwulan ? triwulan : "1",
      search: search ? search : "",
    },
  });
}

function syncSakipTriwulan(triwulan) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/sync-skp-sakip-triwulan", // API untuk sync SKP triwulan
    method: "post",
    data: {
      nip: current.username,
      triwulan: triwulan * 1,
    },
  });
}

function storeTriwulan(triwulan, data, id) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  console.log(id);
  return request({
    url: "/store-skp-triwulan", //API untuk simpan/edit SKP triwulan
    method: "post",
    data: {
      id: id ? id : null,
      nip: current.username,
      triwulan: triwulan * 1,
      ...data,
      // "target": data.target,
      id_skp: data.skp_id,
      // "satuan_target": data.satuan_target,
      // "sasaran": data.sasaran != null ? data.sasaran : data.rencana_kinerja,
      // "satuan_waktu": data.satuan_waktu,
      // "waktu": data.waktu,
      // "kualitas": data.kualitas != null ? data.kualitas : 100,
    },
  });
}

function laporTriwulan(data) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/lapor-skp-triwulan", //API pada saat melaporkan SKP triwulan
    method: "post",
    data: {
      nip: current.username,
      id: data.id,
      id_skp: data.skp_id,
      jenis: data.bulan,
      realisasi: data.realisasi,
      satuan_realisasi: data.satuan_realisasi,
      realisasi_pagu: data.realisasi_pagu,
      capaian: data.capaian,
      file: data.file,
      data_relevan: data.data_relevan,
      hambatan: data.hambatan,
      pengukuran_kinerja: data.formulasi_pengukuran,
      id_indikator_esakip: data.id_indikator_esakip,
      level: data.level,
    },
  });
}

function hapusSKPTriwulan(id, triwulan) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/hapus-skp-triwulan",
    method: "post",
    data: {
      nip: current.username,
      id: id,
      triwulan: triwulan,
    },
  });
}

function getSKPYangSudahDilaporkan({ page, perpage, search }) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-skp-yang-sudah-dilaporkan",
    method: "post",
    data: {
      nip: current.username,
      page: page,
      perpage: perpage ? perpage : 20,
      search: search ? search : "",
    },
  });
}

function getSKPTambahan({ page, perpage, search }) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-skp-tambahan",
    method: "post",
    data: {
      nip: current.username,
      page: page,
      perpage: perpage ? perpage : 20,
      search: search ? search : "",
    },
  });
}

function getDataValidasiSKPTriwulan(triwulan) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-data-validasi-skp-triwulan",
    method: "post",
    data: {
      nip: current.username,
      bulan: triwulan,
    },
  });
}

function getDataValidasiSKPTahunan() {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-data-validasi-skp-tahunan",
    method: "post",
    data: {
      nip: current.username,
      bulan: "tahun",
      triwulan: "tahun",
      tahun: 2020,
    },
  });
}

function getDropdownListSKPTahunan(data = null) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  if (data == null) {
    return request({
      url: "/get-dropdownlist-skp-tahunan",
      method: "post",
      data: {
        nip: current.username,
      },
    });
  } else {
    return request({
      url: "/get-dropdownlist-skp-tahunan",
      method: "post",
      data: {
        ...data,
        nip: current.username,
      },
    });
  }
}

function syncSakipTahunan() {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/sync-skp-sakip-tahunan",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function storeSKPTahunan(data) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/store-skp-tahunan",
    method: "post",
    data: {
      ...data,
      nip: current.username,
    },
  });
}

function laporSKPTahunan(data) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "lapor-skp-tahunan",
    method: "post",
    data: {
      ...data,
      nip: current.username,
    },
  });
}

function exportSKPTahunan(id_konf, periode) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "export-skp-tahunan",
    method: "post",
    data: {
      nip: current.username,
      id_skp_konfigurasi: id_konf,
      periode: periode,
    },
  });
}

function hapusSKPTahunan(id, super_) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "hapus-skp-tahunan",
    method: "post",
    data: {
      nip: current.username,
      id: id,
      super: super_,
    },
  });
}

function getPengaturanSKP() {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "get-pengaturan-skp",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function editPengaturanSKP(item) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "edit-pengaturan-skp",
    method: "post",
    data: {
      ...item,
      nip: current.username,
    },
  });
}

function bukaInputSKPGetDataPegawai({ page, perpage, search, skpd }) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  console.log(page, perpage, search, skpd);
  return request({
    url: "/buka-input-skp-get-data-pegawai",
    method: "post",
    data: {
      role: current.role,
      perpage: perpage,
      page: page,
      search: search,
      order: "peg_nama",
      order_direction: "asc",
      satuan_kerja_id: skpd ? skpd : "",
    },
  });
}

function bukaInputSKPGetDataPLT({ page, perpage, search, skpd }) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }

  return request({
    url: "/buka-input-skp-get-data-pegawai-plt",
    method: "post",
    data: {
      role: current.role,
      perpage: perpage,
      page: page,
      search: search,
      order: "jabatan_nama",
      satuan_kerja_id: skpd ? skpd : "",
    },
  });
}

function satuanSKP() {
  return request({
    url: "/tambah-skp-get-satuan-skp",
    method: "post",
  });
}

function getPegawaiByNip(nip) {
  return request({
    url: "/get-pegawai-by-nip",
    method: "post",
    data: {
      nip: nip,
    },
  });
}

function getTupoksi() {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "tambah-skp-get-tupoksi-by-nip",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function getKegiatan() {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "tambah-skp-get-kegiatan-by-nip",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function getSasaranStrategis() {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "tambah-skp-get-sasaran-strategis-by-nip",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function getSKPAtasan(data) {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "tambah-skp-get-skp-atasan-by-nip",
    method: "post",
    data: {
      ...data,
      nip: current.username,
    },
  });
}

function skpGetDetail(bulan, id) {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "skp-get-detail-skp",
    method: "post",
    data: {
      nip: current.username,
      bulan: bulan,
      id: id,
    },
  });
}

function feedbackEvaluasi(data, jenis) {
  console.log(data, jenis);
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  console.log(
    data.id,
    jenis,
    data.feedback_evaluasi_analisis_kinerja,
    data.is_feedback,
    current.username
  );
  console.log("dummy post");

  return request({
    url: "feedback-evaluasi-kinerja-skp",
    method: "post",
    data: {
      id: data.id,
      jenis: jenis,
      // "evaluasi_analisis_kinerja": data.evaluasi_analisis_kinerja,
      feedback_evaluasi_analisis_kinerja:
        data.feedback_evaluasi_analisis_kinerja,
      is_feedback: true,
      nip: current.username,
    },
  });
}

function tolakValidasiSKP(triwulan, data) {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "tolak-validasi-skp-triwulan",
    method: "post",
    data: {
      id: data.id,
      jenis: triwulan,
      keterangan: data.keterangan,
      nip: current.username,
    },
  });
}

function terimaValidasiSKP(triwulan, data) {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  console.log(data);
  return request({
    url: "terima-validasi-skp-triwulan",
    method: "post",
    data: {
      ...data,
      // "id": data.id,
      jenis: triwulan,
      // "realisasi": data.realisasi,
      // "satuan_realisasi": data.satuan_realisasi,
      nip: current.username,
      nip_bawahan: data.nip18,
      // "evaluasi_analisis_kinerja": data.evaluasi_analisis_kinerja,
      // "kualitas_mutu": data.rating
    },
  });
}

function submitValidasiSKPTahunan(tahunan, data) {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  console.log(data);
  return request({
    url: "submit-validasi-skp-tahunan",
    method: "post",
    data: {
      ...data,
      // "id": data.id,
      jenis: tahunan,
      // "realisasi": data.realisasi,
      nip: current.username,
      // "evaluasi_analisis_kinerja": data.evaluasi_analisis_kinerja,
      // "kualitas_mutu": data.rating,
      nip_bawahan: data.nip18,
      // "satuan_realisasi": data.satuan_target,
      // "periode_tutup_skp": true
    },
  });
}

function uploadSKPEvidence(file, jenis) {
  console.log(file, jenis);
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  let url;

  if (jenis == "triwulan") {
    url = "skp-triwulan-upload-evidence";
  } else if (jenis == "tahunan") {
    url = "skp-tahunan-upload-evidence";
  } else {
    console.error("Jenis tidak boleh kosong", jenis);
    return;
  }

  var formData = new FormData();
  formData.append("nip", current.username);
  formData.append("file", file);
  formData.append("token", store.getters["user/token"]);

  console.log(formData);
  return request({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

function getAktivitasTerkait(id) {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "get-get-aktivitas-terkait-skp",
    method: "post",
    data: {
      nip: current.username,
      id_sasaran_kinerja: id,
    },
  });
}

function storeAssignmentsSKP(data) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/skp-store-assignments",
    method: "post",
    data: {
      ...data,
      nip: current.username,
    },
  });
}

function storeSKPTambahan(data, id) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/skp-tambahan-store-data",
    method: "post",
    data: {
      id: id != null ? id : null,
      ...data,
      nip: current.username,
    },
  });
}

function storeLampiranAngkaKredit(data) {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/skp-store-lampiran-angka-kredit",
    method: "post",
    data: {
      ...data,
      nip: current.username,
    },
  });
}

function getListPeriodeSKP() {
  var current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/skp-tahunan-get-periode",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function getDataPengaturanSKP({ page, perpage, search }) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/skp-get-list-data-pengaturan",
    method: "post",
    data: {
      nip: current.username,
      page: page,
      perpage: perpage ? perpage : 20,
      search: search ? search : "",
    },
  });
}

function hapusSKPTambahan(id) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/skp-tambahan-delete-data",
    method: "post",
    data: {
      nip: current.username,
      id: id,
    },
  });
}

function insertPenilaianSKP() {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/rpt-insert-penilaian-skp",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function hapusPengaturanSKP(id) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/hapus-pengaturan-skp",
    method: "post",
    data: {
      nip: current.username,
      id: id,
    },
  });
}

function getLampiranSKP(nip) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  var nip_ = nip ? nip : current.username;
  return request({
    url: "/get-lampiran-skp",
    method: "post",
    data: {
      nip: nip_,
      // nip: current.username
    },
  });
}

function storeLampiranSKP(data) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/store-lampiran-skp",
    method: "post",
    data: {
      nip: current.username,
      dukungan_sumber_daya: data.dukungan_sumber_daya,
      skema_pertanggungjawaban: data.skema_pertanggungjawaban,
      konsekuensi: data.konsekuensi,
    },
  });
}

function getEkspektasiPerilakuKerja(peg_nip, triwulan, tahun) {
  return request({
    url: "/get-ekspektasi-perilaku",
    method: "post",
    data: {
      nip: peg_nip,
      triwulan: triwulan,
      tahun: tahun,
    },
  });
}

function storeEkspektasiPerilakuKerja(data) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/store-ekspektasi-perilaku",
    method: "post",
    data: {
      nip: data.nip,
      respon: data.respon,
      bulan: data.bulan != undefined ? data.bulan : null,
      triwulan: data.triwulan != undefined ? data.triwulan : null,
      tahun: data.tahun != undefined ? data.tahun : null,
      berorientasi_pelayanan:
        data.respon == true
          ? data.r_berorientasi_pelayanan
          : data.e_berorientasi_pelayanan,
      akuntabel: data.respon == true ? data.r_akuntabel : data.e_akuntabel,
      kompeten: data.respon == true ? data.r_kompeten : data.e_kompeten,
      harmonis: data.respon == true ? data.r_harmonis : data.e_harmonis,
      loyal: data.respon == true ? data.r_loyal : data.e_loyal,
      adaptif: data.respon == true ? data.r_adaptif : data.e_adaptif,
      kolaboratif:
        data.respon == true ? data.r_kolaboratif : data.e_kolaboratif,
      nip_atasan: current.username,
      bobot: data.respon == true ? data.bobot : null,
    },
  });
}

function insertPenilaianSKPPermenpan62022(triwulan) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/insert-penilaian-skp-permenpan6",
    method: "post",
    data: {
      nip: current.username,
      triwulan: triwulan,
    },
  });
}

function insertPenilaianSKPPermenpan6(triwulan, nip) {
  return request({
    url: "/insert-penilaian-skp-permenpan6",
    method: "post",
    data: {
      nip: nip,
      triwulan: triwulan,
    },
  });
}

function getPenilaianSKPPermenpan6() {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-penilaian-skp-pegawai-permenpan6",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function getDataVerifikasiSKP() {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-data-verifikasi-skp",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function storeVerifikasiSKP(id, jenis, verifikasi, keterangan_verifikasi) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/store-verifikasi-skp",
    method: "post",
    data: {
      nip: current.username,
      id: id,
      jenis: jenis,
      verifikasi: verifikasi,
      keterangan_verifikasi: keterangan_verifikasi,
    },
  });
}

function getPegawaiSKPD(kode_skpd) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-pegawai-skpd",
    method: "post",
    data: {
      nip: current.username,
      kode_skpd: kode_skpd,
    },
  });
}

function getDataDistribusiPenilaianKinerja() {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-data-distribusi-penilaian-kinerja",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function getAtasan() {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-penilai",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function getAtasanPenilai() {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-penilai-atasan",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function getListTutupSKP({
  page,
  perpage,
  search,
  order,
  order_direction,
  kode_skpd,
}) {
  var current = store.state.user.data;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/admin-get-list-tutup-skp",
    method: "post",
    data: {
      nip: current.username,
      page: page,
      perpage: perpage ? perpage : 20,
      search: search ? search : "",
      order: order ? order : "",
      kode_skpd: kode_skpd,
      order_direction: order_direction ? order_direction : "asc",
    },
  });
}

function storeKeteranganBobotSKPTriwulan(id, nip, keterangan_bobot) {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/store-keterangan-bobot-skp-triwulan",
    method: "post",
    data: {
      nip_atasan: current.username,
      nip: nip,
      id: id,
      keterangan_bobot: keterangan_bobot,
    },
  });
}

function getBawahanValidasiSKP() {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-bawahan-validasi-skp",
    method: "post",
    data: {
      nip: current.username,
    },
  });
}

function getBawahanValidasiSKPPerNIP(nip, bulan) {
  let current = store.state.user.current;
  let user = store.state.user;
  if (
    user.current.role.includes("PLT") ||
    user.current.role.includes("Kepala UPT Puskesmas") ||
    user.current.role.includes("PLH")
  ) {
    current.username = _g.overrideWithPLT(user.current.role);
  } else {
    current.username = user.current.username;
  }
  return request({
    url: "/get-data-validasi-per-nip",
    method: "post",
    data: {
      nip_atasan: current.username,
      nip: nip,
      bulan: bulan,
    },
  });
}

export default SKPService;
